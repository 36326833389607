import './App.css';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function App() {

  const [copied, setCopied] = useState(false);
  const text = window.location.origin;

  const copy1 = () => {
    navigator.clipboard.writeText(text).then(() => {
      alert("success")
    }, (err) => {
      alert(err);
      // swal("Error", "Failed to copy", "error")
    })
  }

  const copy2 = () => {
    alert("success")
    setCopied(true)
  }
  return (
    <div className="App">
      <header className="App-header">
        <button onClick={copy1}>
          Navigator
        </button>

        <CopyToClipboard text={text} onCopy={copy2}>
          <button id="inviteLink">
            Copy Invite
          </button>
        </CopyToClipboard>
      </header>
    </div>
  );
}

export default App;
